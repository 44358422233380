// app/error.tsx
'use client';

import { Button } from '@/components/atom/button';
import { useMixpanel } from '@/providers/MixPanelProvider';

export default function Error({ error, reset }: { error: Error; reset: () => void }) {
  const { trackEvent } = useMixpanel();
  return (
    <div>
      <h2>Something went wrong!</h2>
      <p>{error.message}</p>
      <Button
        onClick={() => {
          reset();
          trackEvent('button_click', { name: 'Try again to fix error' });
        }}
      >
        Try again
      </Button>
    </div>
  );
}
